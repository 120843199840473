import {
  START_LOADING_PRODUCT_CATEGORIES,
  LOAD_PRODUCT_CATEGORIES,
  END_LOADING_PRODUCT_CATEGORIES,
  START_ADD_PRODUCT_CATEGORY,
  START_DELETE_PRODUCT_CATEGORY,
  END_DELETE_PRODUCT_CATEGORY,
  START_EDIT_PRODUCT_CATEGORY,
  END_EDIT_PRODUCT_CATEGORY,
  END_ADD_PRODUCT_CATEGORY,
  START_UPLOAD_CATEGORY,
  END_UPLOAD_CATEGORY
} from '../constants/productCategoriesConstants';

const initialState = {
  isDeleteCtegoryLoading: false,
  isAddCategoryLoading: false,
  isEditCategoryLoading: false,
  isGetCategoriesLoading: false,
  isUploadCategoryLoading: false,
  productCategoriesList: [],
  sortOrder: 'desc',
  sortField: 'createdAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const productCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_ADD_PRODUCT_CATEGORY:
      return { ...state, isAddCategoryLoading: true };
    case END_ADD_PRODUCT_CATEGORY:
      return { ...state, isAddCategoryLoading: false };
    case START_UPLOAD_CATEGORY:
      return { ...state, isUploadCategoryLoading: true };
    case END_UPLOAD_CATEGORY:
      return { ...state, isUploadCategoryLoading: false };
    case START_EDIT_PRODUCT_CATEGORY:
      return { ...state, isEditCategoryLoading: true };
    case END_EDIT_PRODUCT_CATEGORY:
      return { ...state, isEditCategoryLoading: false };
    case START_DELETE_PRODUCT_CATEGORY:
      return { ...state, isDeleteCategoryLoading: true };
    case END_DELETE_PRODUCT_CATEGORY:
      return { ...state, isDeleteCategoryLoading: false };

    case START_LOADING_PRODUCT_CATEGORIES:
      return { ...state, isGetCategoriesLoading: true };

    case LOAD_PRODUCT_CATEGORIES:
      return {
        ...state,
        productCategoriesList: action.payload.data,
        count: action.payload.pagination.totalCount,
        page: action.payload.pagination.pageNumber,
        pageSize: action.payload.pagination.pageLimit,
        totalPages: action.payload.pagination.pageCount,
        sortOrder: action.payload.sort.order,
        sortField: action.payload.sort.field
      };

    case END_LOADING_PRODUCT_CATEGORIES:
      return { ...state, isGetCategoriesLoading: false };
    default:
      return state;
  }
};

export default productCategoriesReducer;
