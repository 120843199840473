import {
  START_LOADING,
  END_LOADING,
  START_LOADING_ORDERS,
  LOAD_ORDERS,
  END_LOADING_ORDERS,
  START_PROCESSING_ORDERS,
  END_PROCESSING_ORDERS,
  START_CONFIRMING_ORDERS,
  END_CONFIRMING_ORDERS,
  PROCESS_OPERATION_SUCCESS,
  CONFIRM_OPERATION_SUCCESS,
  START_ASSIGNING_DELIVERY,
  END_ASSIGNING_DELIVERY,
  CONFIRM_ASSIGNING_DELIVERY
} from '../constants/ordersConstants';

const initialState = {
  isLoading: false,
  isProcessing: false,
  ordersList: [],
  isSuccess: false,
  isProcessingSuccess: false,
  isConfirmSuccess: false,
  isConfirming: false,
  isAssigningDelivery: false,
  isConfirmAssigningDelivery: false,
  sortOrder: 'desc',
  sortField: 'createdAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case START_LOADING_ORDERS:
      return { ...state, isLoading: true };
    case START_PROCESSING_ORDERS:
      return { ...state, isProcessing: true };
    case START_CONFIRMING_ORDERS:
      return { ...state, isConfirming: true };
    case START_ASSIGNING_DELIVERY:
      return { ...state, isAssigningDelivery: true };

    case LOAD_ORDERS:
      return {
        ...state,
        ordersList: action.payload.data,
        count: action.payload.pagination.totalCount,
        page: action.payload.pagination.pageNumber,
        pageSize: action.payload.pagination.pageLimit,
        totalPages: action.payload.pagination.totalCount,
        sortOrder: action.payload.sort.order,
        sortField: action.payload.sort.field
      };

    case END_LOADING_ORDERS:
      return { ...state, isLoading: false };
    case END_PROCESSING_ORDERS:
      return { ...state, isProcessing: false };
    case END_CONFIRMING_ORDERS:
      return { ...state, isConfirming: false };
    case END_ASSIGNING_DELIVERY:
      return { ...state, isAssigningDelivery: false };
    case PROCESS_OPERATION_SUCCESS:
      return { ...state, isProcessingSuccess: true };
    case CONFIRM_OPERATION_SUCCESS:
      return { ...state, isConfirmSuccess: true };
    case CONFIRM_ASSIGNING_DELIVERY:
      return { ...state, isConfirmAssigningDelivery: true };

    default:
      return state;
  }
};

export default ordersReducer;
