import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useNavigate } from 'react-router-dom';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(5)
  },
  userIcon: {
    margin: 'auto',
    fontSize: 50,
    cursor: 'pointer'
    // '&:hover': {
    //   boxShadow: '-1px 0px 24px 0px rgba(255,255,255,0.63)'
    // }
  },
  userButton: {
    backgroundColor: 'transparent',
    height: '10px',
    width: '10px',
    color: theme.palette.primary.main,
    // '&:hover': {
    //   boxShadow: '-1px 0px 24px 0px rgba(255,255,255,0.63)'
    // }
  }
}));

export default function ProfileMenu() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const logOut = () => {
    localStorage.clear();
    navigate('/login');
  };
  const openProfile = () => {
    navigate('/app/account');
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        aria-controls="simple-menu"
        aria-haspopup="true"
        disableFocusRipple
        disableRipple
        className={classes.userButton}
      >
        <AccountCircleIcon className={classes.userIcon} />
      </IconButton>
      <Menu
        className={classes.root}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem dense onClick={openProfile}>
          Profile
        </MenuItem>
        <MenuItem dense onClick={logOut}>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
