export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';

export const START_LOADING_STOCK = 'START_LOADING_STOCK';
export const LOAD_STOCK = 'LOAD_STOCK';
export const END_LOADING_STOCK = 'END_LOADING_STOCK';

export const STOCK_OPERATION_SUCCESS = 'STOCK_OPERATION_SUCCESS';

export const START_STOCK_UPLOAD = 'START_STOCK_UPLOAD';
export const UPLOAD_STOCK = 'UPLOAD_STOCK';
export const END_STOCK_UPLOAD = 'END_STOCK_UPLOAD';

export const START_ADD_STOCK = 'START_ADD_STOCK';
export const ADD_STOCK = 'ADD_STOCK';
export const END_ADD_STOCK = 'END_ADD_STOCK';
export const ADD_STOCK_SUCCESS = 'ADD_STOCK_SUCCESS';

export const START_EDIT_STOCK = 'START_EDIT_STOCK';
export const EDIT_STOCK = 'EDIT_STOCK';
export const END_EDIT_STOCK = 'END_EDIT_STOCK';
export const EDIT_STOCK_SUCCESS = 'EDIT_STOCK_SUCCESS';

export const START_DELETE_STOCK = 'START_DELETE_STOCK';
export const DELETE_STOCK = 'DELETE_STOCK';
export const END_DELETE_STOCK = 'END_DELETE_STOCK';
