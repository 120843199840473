import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import lazyLoading from 'src/components/LazyLoading';
import ProgressBar from 'react-topbar-progress-indicator';
// import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import NotFoundView from 'src/views/errors/NotFoundView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';

ProgressBar.config({
  barColors: {
    // 0: '#ffe600',
    0: '#EF404F',
    '1.0': '#fff'
  },
  shadowBlur: 0
});

const CreateOrderView = lazyLoading(
  () => import('src/views/orders/OrderView/CreateOrder'),
  {
    fallback: <ProgressBar />
  }
);

const DashboardView = lazyLoading(
  () => import('src/views/reports/DashboardView'),
  {
    fallback: <ProgressBar />
  }
);

const AccountView = lazyLoading(() => import('src/views/account/AccountView'), {
  fallback: <ProgressBar />
});

const ProductCategoryView = lazyLoading(
  () => import('src/views/productCategories/ProductCategoriesListView'),
  {
    fallback: <ProgressBar />
  }
);

const UsersView = lazyLoading(() => import('src/views/users/UserListView'), {
  fallback: <ProgressBar />
});

const UserGroupsView = lazyLoading(
  () => import('src/views/userGroups/UserGroupView'),
  {
    fallback: <ProgressBar />
  }
);

const PermissionsView = lazyLoading(
  () => import('src/views/permissions/PermissionsListView'),
  {
    fallback: <ProgressBar />
  }
);

const CustomersView = lazyLoading(
  () => import('src/views/customer/CustomerListView'),
  {
    fallback: <ProgressBar />
  }
);

const SuperMarketsView = lazyLoading(
  () => import('src/views/supermarkets/SuperMarketsListView'),
  {
    fallback: <ProgressBar />
  }
);

const ProductsListView = lazyLoading(
  () => import('src/views/products/ProductsListView'),
  {
    fallback: <ProgressBar />
  }
);

const StockView = lazyLoading(() => import('src/views/stock/StockListView'), {
  fallback: <ProgressBar />
});

const OrderView = lazyLoading(() => import('src/views/orders/OrderView'), {
  fallback: <ProgressBar />
});

const NewOrdersView = lazyLoading(
  () => import('src/views/orders/OrderView/newOrders'),
  {
    fallback: <ProgressBar />
  }
);

const ProcessingOrdersView = lazyLoading(
  () => import('src/views/orders/OrderView/processingOrders'),
  {
    fallback: <ProgressBar />
  }
);

const PendingPaymentOrdersView = lazyLoading(
  () => import('src/views/orders/OrderView/pendingPaymentOrders'),
  {
    fallback: <ProgressBar />
  }
);

const ConfirmedPaymentOrdersView = lazyLoading(
  () => import('src/views/orders/OrderView/confirmedPaymentOrders'),
  {
    fallback: <ProgressBar />
  }
);

const ShippingOrdersView = lazyLoading(
  () => import('src/views/orders/OrderView/shippingOrders'),
  {
    fallback: <ProgressBar />
  }
);

const LoginView = lazyLoading(() => import('src/views/auth/LoginView'), {
  fallback: <ProgressBar />
});

const ResetPasswordView = lazyLoading(
  () => import('src/views/auth/ResetPassword'),
  {
    fallback: <ProgressBar />
  }
);

const NewPasswordView = lazyLoading(
  () => import('src/views/auth/NewPassword'),
  {
    fallback: <ProgressBar />
  }
);

const FulfilledOrdersView = lazyLoading(
  () => import('src/views/orders/OrderView/fulfilledOrders'),
  {
    fallback: <ProgressBar />
  }
);

const routes = isLoggedIn => [
  {
    path: 'app',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'orders', element: <OrderView /> },
      { path: 'orders/create', element: <CreateOrderView /> },
      { path: 'orders/new', element: <NewOrdersView /> },
      { path: 'orders/processing', element: <ProcessingOrdersView /> },
      { path: 'orders/pendingPayment', element: <PendingPaymentOrdersView /> },
      {
        path: 'orders/confirmedPayment',
        element: <ConfirmedPaymentOrdersView />
      },
      { path: 'orders/shipping', element: <ShippingOrdersView /> },
      { path: 'orders/fulfilled', element: <FulfilledOrdersView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'product-categories', element: <ProductCategoryView /> },
      { path: 'products', element: <ProductsListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: 'user-groups', element: <UserGroupsView /> },
      { path: 'permissions', element: <PermissionsView /> },
      { path: 'users', element: <UsersView /> },
      { path: 'customers', element: <CustomersView /> },
      { path: 'supermarkets', element: <SuperMarketsView /> },
      { path: 'stock', element: <StockView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: !isLoggedIn ? <MainLayout /> : <Navigate to="/app/dashboard" />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: 'resetpassword', element: <ResetPasswordView /> },
      { path: 'auth/reset-password/:resettoken', element: <NewPasswordView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
