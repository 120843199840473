import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 0,
    padding: 0
  },
  addUser: {
    marginBottom: 10
  }
}));

const Toolbar = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.addUser, className)} {...rest}>
      <Box display="flex" p={0} alignItems="center">
        <Box p={0} flexGrow={1}>
          <Typography variant="h4">Customers</Typography>
        </Box>
        {/*<Box p={0}>*/}
        {/*  <FormDialog />*/}
        {/*</Box>*/}
        {/*<Box p={0}>*/}
        {/*  <CsvUpload />*/}
        {/*</Box>*/}
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string
};

export default Toolbar;
