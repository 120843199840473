import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Typography
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from 'src/components/Logo';
import ProfileMenu from './ProfileMenu';

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: `3px solid #F4F6F8`,
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      zIndex: 1
    },
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    color: 'black'
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const userData = JSON.parse(localStorage.getItem('profileData'));

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <Box alignItems="center" display="flex" flexDirection="row" p={0}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              mr={2}
            >
              <Typography className={classes.name} variant="subtitle2">
                {userData ? userData.name : ''}
              </Typography>
              <Typography className={classes.name} variant="caption">
                {userData ? userData.role : ''}
              </Typography>
            </Box>
            <ProfileMenu />
          </Box>
          {/* <IconButton color="inherit" onClick={logOut}>
            <InputIcon />
          </IconButton> */}
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
