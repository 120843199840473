export const START_LOADING_PRODUCT_CATEGORIES =
  'START_LOADING_PRODUCT_CATEGORIES';
export const LOAD_PRODUCT_CATEGORIES = 'LOAD_PRODUCT_CATEGORIES';
export const END_LOADING_PRODUCT_CATEGORIES = 'END_LOADING_PRODUCT_CATEGORIES';

export const START_ADD_PRODUCT_CATEGORY = 'START_ADD_PRODUCT_CATEGORY';
export const ADD_PRODUCT_CATEGORY = 'ADD_PRODUCT_CATEGORY';
export const END_ADD_PRODUCT_CATEGORY = 'END_ADD_PRODUCT_CATEGORY';

export const START_EDIT_PRODUCT_CATEGORY = 'START_EDIT_PRODUCT_CATEGORY';
export const EDIT_PRODUCT_CATEGORY = 'EDIT_PRODUCT_CATEGORY';
export const END_EDIT_PRODUCT_CATEGORY = 'END_EDIT_PRODUCT_CATEGORY';

export const START_DELETE_PRODUCT_CATEGORY = 'START_DELETE_PRODUCT_CATEGORY';
export const DELETE_PRODUCT_CATEGORY = 'DELETE_PRODUCT_CATEGORY';
export const END_DELETE_PRODUCT_CATEGORY = 'END_DELETE_PRODUCT_CATEGORY';

export const START_UPLOAD_CATEGORY = 'START_PRODUCT_CATEGORY';
export const UPLOAD_CATEGORY = 'UPLOAD_CATEGORY';
export const END_UPLOAD_CATEGORY = 'END_UPLOAD_CATEGORY';
