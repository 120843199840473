export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
export const START_LOADING_ORDERS = 'START_LOADING_ORDERS';
export const LOAD_ORDERS = 'LOAD_ORDERS';
export const LOAD_NEW_ORDERS = 'LOAD_NEW_ORDERS';
export const LOAD__PROCESSING_ORDERS = 'LOAD__PROCESSING_ORDERS';
export const LOAD_PENDING_PAYMENT_ORDERS = 'LOAD_PENDING_PAYMENT_ORDERS';
export const LOAD_CONFIRMED_PAYMENT_ORDERS = 'LOAD_CONFIRMED_PAYMENT_ORDERS';

export const END_LOADING_ORDERS = 'END_LOADING_ORDERS';
export const START_PROCESSING_ORDERS = 'START_PROCESSING_ORDERS';
export const END_PROCESSING_ORDERS = 'END_PROCESSING_ORDERS';
export const START_CONFIRMING_ORDERS = 'START_CONFIRMING_ORDERS';
export const END_CONFIRMING_ORDERS = 'END_CONFIRMING_ORDERS';
export const PROCESS_OPERATION_SUCCESS = 'PROCESS_OPERATION_SUCCESS';
export const CONFIRM_OPERATION_SUCCESS = 'CONFIRM_OPERATION_SUCCESS';
export const START_ASSIGNING_DELIVERY = 'START_ASSIGNING_DELIVERY';
export const END_ASSIGNING_DELIVERY = 'END_ASSIGNING_DELIVERY';
export const CONFIRM_ASSIGNING_DELIVERY = 'CONFIRM_ASSIGNING_DELIVERY';
