import axios from 'axios';
import authConfig from './authHeader';

const instance = axios.create(authConfig);

class APIError extends Error {
  constructor(statusCode, message, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }

    this.statusCode = statusCode;
    this.message = message;
  }
}

const errorHandler = err => {
  try {
    if (err.response) {
      console.log(err);
      throw new APIError(err.response.status, err.response.data.message);
    } else if (err.request) {
      throw new APIError(503, err.request);
    } else throw APIError(400, err.message);
  } catch (e) {
    return e;
  }
};

// Set the AUTH token for any request
instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

instance.interceptors.response.use(
  response => response,
  error => Promise.reject(errorHandler(error))
);

export const secureapi = instance;
