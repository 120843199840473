import {
  START_ADD_PRODUCT,
  END_ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  START_EDIT_PRODUCT,
  END_EDIT_PRODUCT,
  START_DELETE_PRODUCT,
  END_DELETE_PRODUCT,
  START_LOADING_PRODUCTS,
  LOAD_PRODUCTS,
  END_LOADING_PRODUCTS,
  START_PRODUCT_UPLOAD,
  END_PRODUCT_UPLOAD,
  START_PRODUCT_IMAGES_UPLOAD,
  END_PRODUCT_IMAGES_UPLOAD
} from '../constants/productConstants';

const initialState = {
  isDeleteProductLoading: false,
  isAddProductLoading: false,
  isAddProductSuccess: false,
  isEditProductLoading: false,
  isGetProductsLoading: false,
  isUploadProductLoading: false,
  isUploadProductImagesLoading: false,
  productsList: [],
  sortOrder: 'desc',
  sortField: 'createdAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_ADD_PRODUCT:
      return {
        ...state,
        isAddProductLoading: true,
        isAddProductSuccess: false
      };
    case END_ADD_PRODUCT:
      return {
        ...state,
        isAddProductLoading: false
      };
    case ADD_PRODUCT_SUCCESS:
      return { ...state, isAddProductSuccess: true };
    case START_PRODUCT_UPLOAD:
      return { ...state, isUploadProductLoading: true };
    case END_PRODUCT_UPLOAD:
      return { ...state, isUploadProductLoading: false };
    case START_PRODUCT_IMAGES_UPLOAD:
      return { ...state, isUploadProductImagesLoading: true };
    case END_PRODUCT_IMAGES_UPLOAD:
      return { ...state, isUploadProductImagesLoading: false };
    case START_EDIT_PRODUCT:
      return { ...state, isEditProductLoading: true };
    case END_EDIT_PRODUCT:
      return { ...state, isEditProductLoading: false };
    case START_DELETE_PRODUCT:
      return { ...state, isDeleteProductLoading: true };
    case END_DELETE_PRODUCT:
      return { ...state, isDeleteProductLoading: false };

    case START_LOADING_PRODUCTS:
      return { ...state, isGetProductsLoading: true };

    case LOAD_PRODUCTS:
      return {
        ...state,
        productsList: action.payload.data,
        count: action.payload.pagination.totalCount,
        page: action.payload.pagination.pageNumber,
        pageSize: action.payload.pagination.pageLimit,
        totalPages: action.payload.pagination.pageCount,
        sortOrder: action.payload.sort.order,
        sortField: action.payload.sort.field
      };

    case END_LOADING_PRODUCTS:
      return { ...state, isGetProductsLoading: false };

    default:
      return state;
  }
};

export default productsReducer;
