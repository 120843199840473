import {
  // START_LOADING,
  // END_LOADING,
  START_LOADING_CUSTOMERS,
  LOAD_CUSTOMERS,
  END_LOADING_CUSTOMERS
} from '../constants/customerConstants';
import { addErrorMessage, addSuccessMessage } from './notification';

// const startLoading = () => ({
//   type: START_LOADING
// });
//
// const endLoading = () => ({
//   type: END_LOADING
// });

const startLoadingCustomers = () => ({
  type: START_LOADING_CUSTOMERS
});

const loadCustomers = payload => ({
  type: LOAD_CUSTOMERS,
  payload
});

const endLoadingCustomers = () => ({
  type: END_LOADING_CUSTOMERS
});

const getRequestParams = (page, pageSize, searchTitle) => {
  const params = {};

  if (searchTitle) {
    params['title'] = searchTitle;
  }

  if (page) {
    params['pageNumber'] = page;
  }

  if (pageSize) {
    params['pageLimit'] = pageSize;
  }

  return params;
};

//get customers list
export const getCustomers = (
  secureapi,
  page,
  pageSize,
  searchTitle
) => dispatch => {
  dispatch(startLoadingCustomers());
  const params = getRequestParams(page, pageSize, searchTitle);
  const supermarketId = JSON.parse(localStorage.getItem('profileData'))
    .supermarket_id;
  console.log('params', params);
  const url = `/customers/get-many`;

  return secureapi
    .get(url, { params })
    .then(data => {
      console.log('search results >>>>', data.data.data);
      dispatch(endLoadingCustomers());

      if (!data.data.data) {
        // console.log("no data running");
        dispatch(loadCustomers(data.data));
        dispatch(addErrorMessage('No results found.'));
      } else {
        dispatch(loadCustomers(data.data));
      }
    })
    .catch(({ message }) => {
      dispatch(endLoadingCustomers());

      dispatch(addErrorMessage(message));
    });
};

//get customer profile
export const getCustomerProfile = secureapi => dispatch => {
  dispatch(startLoadingCustomers());
  let superAdmin = false;
  const role = localStorage.getItem('userRole');
  if (role === 'super administrator') superAdmin = true;
  const url = `/account/profile?superAdmin=${superAdmin}`;

  return secureapi
    .get(url)
    .then(res => {
      console.log('profile data', res.data.data);
      localStorage.setItem('profileData', JSON.stringify(res.data.data));
      dispatch(addSuccessMessage(`Login Successful`));
      dispatch(endLoadingCustomers());
    })
    .catch(({ message }) => {
      dispatch(endLoadingCustomers());

      dispatch(addErrorMessage(message));
    });
};
