const accessor = ({ reducer: { customersReducer } }) => customersReducer;

export const getIsLoading = state => accessor(state).isLoading;

export const getCustomersList = state => accessor(state).customersList;

export const getTotalCustomers = state => accessor(state).count;

export const getPageSize = state => accessor(state).pageSize;

export const getTotalPages = state => accessor(state).totalPages;

export const getPageNumber = state => accessor(state).page;
