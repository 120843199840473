import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';
import { AnimatePresence } from 'framer-motion';
import jwt_decode from 'jwt-decode';
const App = () => {
  const checkLoggedIn = () => {
    if (localStorage.getItem('token')) {
      const token = localStorage.getItem('token');
      const decodedToken = jwt_decode(token);
      const currentDate = new Date();
      // JWT exp is in seconds
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        return false;
      }
      return true;
    }
    return false;
  };
  const isLoggedIn = checkLoggedIn();
  const routing = useRoutes(routes(isLoggedIn));

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AnimatePresence exitBeforeEnter>{routing}</AnimatePresence>
    </ThemeProvider>
  );
};

export default App;
