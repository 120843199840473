import React from 'react';
import { makeStyles } from '@material-ui/core';
import yellowLogo from 'src/assets/svg/mulleys-yellow-logo.svg';
import yellowTitle from 'src/assets/svg/yellow-mulleys-title.svg';
import dilLogo from 'src/assets/png/newdillogo.png'

const useStyles = makeStyles(theme => ({
  logoDiv: {
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    marginRight: theme.spacing(2)
  }
}));
const Logo = props => {
  const classes = useStyles();
  return (
    <div className={classes.logoDiv}>
      {/* <img
        className={classes.logo}
        height="45px"
        width="45px"
        alt="Mulleys Logo"
        src={dilLogo}
        {...props}
      /> */}
      <img height="65px" alt="DIL logo" src={dilLogo} {...props} />
    </div>
  );
};

export default Logo;
