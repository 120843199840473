import {
  START_LOADING_GROUPS,
  END_LOADING_GROUPS,
  ADD_GROUP_SUCCESS,
  START_ADD_GROUP,
  END_ADD_GROUP,
  START_EDIT_GROUP,
  EDIT_GROUP_SUCCESS,
  END_EDIT_GROUP,
  LOAD_GROUPS
} from '../constants/userGroupConstants';

const initialState = {
  isLoadingGroups: false,
  isAddGroupSuccess: false,
  isAddGroupLoading: false,
  isEditGroupLoading: false,
  isEditGroupSuccess: false,
  groupsList: [],
  sortOrder: 'desc',
  sortField: 'createdAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const userGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_ADD_GROUP:
      return { ...state, isAddGroupLoading: true };
    case END_ADD_GROUP:
      return { ...state, isAddGroupLoading: false };
    case START_EDIT_GROUP:
      return { ...state, isEditGroupLoading: true };
    case END_EDIT_GROUP:
      return { ...state, isEditGroupLoading: false };
    case START_LOADING_GROUPS:
      return { ...state, isLoadingGroups: true };
    case ADD_GROUP_SUCCESS:
      return { ...state, isAddGroupSuccess: true };
    case EDIT_GROUP_SUCCESS:
      return { ...state, isEditGroupSuccess: true };
    case END_LOADING_GROUPS:
      return { ...state, isLoadingGroups: false };

    case LOAD_GROUPS:
      return {
        ...state,
        groupsList: action.payload.data,
        count: action.payload.pagination.totalCount,
        page: action.payload.pagination.pageNumber,
        pageSize: action.payload.pagination.pageLimit,
        totalPages: action.payload.pagination.pageCount,
        sortOrder: action.payload.sort.order,
        sortField: action.payload.sort.field
      };
    default:
      return state;
  }
};

export default userGroupsReducer;
