import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  IconButton,
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  ShoppingBag as ShoppingBagIcon,
  Users as UsersIcon
} from 'react-feather';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import StoreIcon from '@material-ui/icons/Store';
import StorefrontIcon from '@material-ui/icons/Storefront';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import CategoryIcon from '@material-ui/icons/Category';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import GroupIcon from '@material-ui/icons/Group';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { ShouldRender } from '../../../components/ShouldRender';
import NavItem from './NavItem';
import { canRead } from '../../../components/checkPermissions';

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Dashboard',
    resource: 'dashboard',
    role: 'all'
  },
  {
    href: '/app/orders',
    icon: ShoppingCartOutlinedIcon,
    title: 'Orders',
    resource: 'customer order',
    role: 'sales',
    nestedItems: [
      {
        href: '/app/orders/create',
        title: 'Create Order'
      },
      {
        href: '/app/orders/new',
        title: 'New Orders'
      },
      {
        href: '/app/orders/processing',
        title: 'Processing Orders'
      },
      {
        href: '/app/orders/pendingPayment',
        title: 'Pending Payment'
      },
      {
        href: '/app/orders/confirmedPayment',
        title: 'Confirmed Payment'
      },
      {
        href: '/app/orders/shipping',
        title: 'Shipping'
      },
      {
        href: '/app/orders/fulfilled',
        title: 'Complete'
      }
    ]
  },
  {
    href: '/app/products',
    icon: ShoppingBagIcon,
    title: 'Products',
    resource: 'product',
    role: 'all'
  },
  {
    href: '/app/product-categories',
    icon: CategoryIcon,
    title: 'Product Categories',
    resource: 'product category',
    role: 'all'
  },
  {
    href: '/app/stock',
    icon: ShowChartIcon,
    title: 'Stock',
    resource: 'supermarket stock',
    role: 'all'
  },
  {
    href: '/app/supermarkets',
    icon: StorefrontIcon,
    title: 'Stores',
    resource: 'supermarket',
    role: 'all'
  },
  {
    href: '/app/user-groups',
    icon: GroupIcon,
    title: 'User Groups',
    resource: 'user group',
    role: 'all'
  },
  {
    href: '/app/permissions',
    icon: SettingsIcon,
    title: 'Permissions',
    resource: 'user group permission',
    role: 'all'
  },
  {
    href: '/app/users',
    icon: PeopleOutlineIcon,
    title: 'Users',
    resource: 'user',
    role: 'all'
  },
  {
    href: '/app/customers',
    icon: UsersIcon,
    title: 'Customers',
    resource: 'customer',
    role: 'all'
  }
];

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    [theme.breakpoints.up('md')]: {
      zIndex: 0
    }
  },
  avatar: {
    cursor: 'pointer',
    width: 48,
    height: 48,
    padding: 0
    // backgroundColor: theme.palette.secondary.main
  },
  // userIcon: {
  //   width: 48,
  //   height: 48,
  //   margin: 0
  // },
  branch: {
    paddingLeft: theme.spacing(3),
    padding: theme.spacing(2)
  },
  branchIcon: {
    marginRight: theme.spacing(1)
  },
  profile: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    borderBottom: `3px solid ${theme.palette.secondary.main}`
  },
  userIcon: {
    margin: 'auto',
    fontSize: 60,
    cursor: 'pointer'
  },
  userButton: {
    margin: 'auto',
    backgroundColor: 'transparent',
    height: 60,
    width: 60,
    color: 'white',
    justifyContent: 'center',
    '&:hover': {
      // backgroundColor: theme.palette.secondary.main,
      boxShadow: '-1px 0px 24px 0px rgba(255,255,255,0.63)'
    }
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const userProfile = JSON.parse(localStorage.getItem('profileData'));
  const navigate = useNavigate();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const logOut = () => {
    localStorage.clear();
    navigate('/login');
  };
  const openProfile = () => {
    navigate('/app/account');
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Hidden lgUp>
        <Box
          className={classes.profile}
          p={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <IconButton
            onClick={openProfile}
            aria-controls="simple-menu"
            aria-haspopup="true"
            disableFocusRipple
            disableRipple
            className={classes.userButton}
          >
            <AccountCircleIcon className={classes.userIcon} />
          </IconButton>
          <Typography
            align="center"
            className={classes.name}
            variant="subtitle2"
          >
            {userProfile ? userProfile.name : ''}
          </Typography>
          <Typography align="center" className={classes.name} variant="caption">
            {userProfile ? userProfile.user_group_name : ''}
          </Typography>
        </Box>
      </Hidden>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        className={classes.branch}
      >
        <StoreIcon color="primary" className={classes.branchIcon} />
        <Typography color="primary" variant="subtitle2">
          {userProfile.supermarket_name}
        </Typography>
      </Box>
      <Divider />
      <Box p={0} pt={1}>
        <List>
          {items.map((item, index) => (
            <div key={index}>
              <ShouldRender if={canRead(item.resource)}>
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  nestedItems={item.nestedItems}
                />
              </ShouldRender>
              <ShouldRender if={item.resource === 'dashboard'}>
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  nestedItems={item.nestedItems}
                />
              </ShouldRender>
            </div>
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Divider />
      <Box display="flex" justifyContent="center" mt={1} mb={1}>
        <Button
          onClick={() => logOut()}
          color="primary"
          component="a"
          variant="text"
          startIcon={<PowerSettingsNewIcon />}
        >
          Logout
        </Button>
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" mb={1} mt={1}>
        <Typography color="textSecondary" variant="caption" align="center">
          {`Copyright © ${new Date().getFullYear()} Data Integrated Limited`}
        </Typography>
        <Typography color="textSecondary" variant="caption" align="center">
          All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <PerfectScrollbar>{content}</PerfectScrollbar>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          <PerfectScrollbar>{content}</PerfectScrollbar>
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
