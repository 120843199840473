import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Img } from 'react-image';
import {
  Typography,
  MenuItem,
  Dialog,
  IconButton,
  Divider
} from '@material-ui/core';
import MuiLinearProgress from '@material-ui/core/LinearProgress';
import CloseIcon from '@material-ui/icons/Close';

const LinearProgress = withStyles({
  //   colorPrimary: {
  //     backgroundColor: 'red'
  //   },
  barColorPrimary: {
    backgroundColor: '#dbdbdb'
  }
})(MuiLinearProgress);

const useStyles = makeStyles(theme => ({
  root: {
    height: 270,
    maxWidth: 500,
    display: 'flex'
    // flexDirection
  },
  image: {
    width: 250
  },
  content: {
    marginLeft: 10,
    paddingTop: 5,
    paddingRight: 5,
    padding: theme.spacing(1),
    width: 300
  },
  loader: {
    width: 250,
    backgroundColor: '#EEF3F2'
  },
  imageItem: {
    margin: 'auto auto',
    color: '#f5f5f5',
    background: '#f5f5f5'
  },
  closeButton: {
    color: theme.palette.grey[500]
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  headerText: {
    flexGrow: 1
  },
  divider: {
    marginBottom: 4
  }
}));

export default function ViewCustomerCard({ customer }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <MenuItem dense onClick={handleClickOpen}>
        View
      </MenuItem>
      <Dialog
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Card className={classes.root}>
          {/*<Img*/}
          {/*  className={classes.image}*/}
          {/*  src={[`${customer.image}`]}*/}
          {/*  loader={*/}
          {/*    <div className={classes.loader}>*/}
          {/*      <LinearProgress className={classes.imageItem} />*/}
          {/*    </div>*/}
          {/*  }*/}
          {/*/>*/}
          <div className={classes.content}>
            <div className={classes.header}>
              <Typography
                className={classes.headerText}
                variant="h6"
                color="textSecondary"
              >
                Customer Details
              </Typography>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleClose}
              >
                {' '}
                <CloseIcon />
              </IconButton>
            </div>
            <div className={classes.title}>
              <Typography gutterBottom variant="h5" component="h2">
                {customer.name}
              </Typography>
            </div>
            <Divider className={classes.divider} />
            <Typography variant="body2" color="textSecondary">
              Phone Number
            </Typography>
            <Typography gutterBottom variant="body2">
              {customer.phone_number}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Estate
            </Typography>
            <Typography gutterBottom variant="h6">
              {customer.estate}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Block
            </Typography>
            <Typography variant="h6">{customer.block}</Typography>
            <Typography variant="body2" color="textSecondary">
              House
            </Typography>
            <Typography variant="h6">{customer.house}</Typography>
          </div>

          {/* <CardActions>
            <Button size="small" color="primary">
              Share
            </Button>
            <Button size="small" color="primary">
              Learn More
            </Button>
          </CardActions> */}
        </Card>
      </Dialog>
    </div>
  );
}
