export const START_LOADING_PERMISSIONS = 'START_LOADING_PERMISSIONS';
export const LOAD_PERMISSIONS = 'LOAD_PERMISSIONS';
export const END_LOADING_PERMISSIONS = 'END_LOADING_PERMISSIONS';

export const START_ADD_PERMISSIONS = 'START_ADD_PERMISSIONS';
export const ADD_PERMISSION_SUCCESS = 'ADD_PERMISSION_SUCCESS';
export const END_ADD_PERMISSIONS = 'END_ADD_PERMISSIONS';

export const START_EDIT_PERMISSIONS = 'START_EDIT_PERMISSIONS';
export const EDIT_PERMISSION_SUCCESS = 'EDIT_PERMISSION_SUCCESS';
export const END_EDIT_PERMISSIONS = 'END_EDIT_PERMISSIONS';

export const START_DELETE_PERMISSIONS = 'START_DELETE_PERMISSIONS';
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS';
export const END_DELETE_PERMISSIONS = 'END_DELETE_PERMISSIONS';
