import {
  START_LOADING_SUPERS,
  LOAD_SUPERS,
  END_LOADING_SUPERS
} from '../constants/supermarketsContants';

const initialState = {
  isLoading: false,
  supersList: [],
  sortOrder: 'desc',
  sortField: 'createdAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const supermarketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_SUPERS:
      return { ...state, isLoading: true };

    case LOAD_SUPERS:
      console.log('super payload>>>>', action.payload.sort.field);
      return {
        ...state,
        supersList: action.payload.data,
        count: action.payload.pagination.totalCount,
        page: action.payload.pagination.pageNumber,
        pageSize: action.payload.pagination.pageLimit,
        totalPages: action.payload.pagination.pageCount,
        sortOrder: action.payload.sort.order,
        sortField: action.payload.sort.field
      };

    case END_LOADING_SUPERS:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default supermarketsReducer;
