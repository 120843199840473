import React from 'react';
import { Container, Typography, makeStyles } from '@material-ui/core';
import emptySVG from 'src/assets/svg/empty.svg';

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #b9b9b982',
    borderRadius: '3px',
    position: 'relative',
    minHeight: '400px',
    alignItems: 'center'
  },
  items: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    position: 'absolute',
    top: '40%',
    justifyContent: 'center'
  },
  text: {},
  image: {
    color: 'grey'
  }
}));

const EmptyState = ({ message }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container disableGutters maxWidth="xl" className={classes.items}>
        <img
          height="50px"
          src={emptySVG}
          alt="Logo"
          className={classes.image}
        />
        <Typography
          color="textSecondary"
          align="center"
          className={classes.text}
          variant="h6"
        >
          {message}
        </Typography>
      </Container>
    </div>
  );
};

export default EmptyState;
