import React, { useState } from 'react';
import { Box, Container, makeStyles, Paper } from '@material-ui/core';
import { motion } from 'framer-motion';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100%',
    padding: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1),
    paddingTop: 1,
    minHeight: 'calc(100vh - 80px)'
  }
}));

const CustomerListView = () => {
  const classes = useStyles();
  const containerVariants = {
    hidden: {
      opacity: 0.5,
      scale: 1.1,
      y: 10
    },
    visible: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: { delay: 0, duration: 0.5 }
    }
  };

  return (
    <PerfectScrollbar>
      <Page className={classes.root} title="Customers">
        <motion.main
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          <Paper className={classes.paper}>
            <Container disableGutters maxWidth={false}>
              <Box mt={3}>
                <Toolbar />
                <Results />
              </Box>
            </Container>
          </Paper>
        </motion.main>
      </Page>
    </PerfectScrollbar>
  );
};

export default CustomerListView;
