export const START_LOADING_GROUPS = 'START_LOADING_GROUPS';

export const END_LOADING_GROUPS = 'END_LOADING_GROUPS';
export const LOAD_GROUPS = 'LOAD_GROUPS';

export const START_ADD_GROUP = 'START_ADD_GROUP';
export const ADD_GROUP = 'ADD_GROUP';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const END_ADD_GROUP = 'END_ADD_GROUP';

export const START_EDIT_GROUP = 'START_EDIT_GROUP';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const EDIT_GROUP = 'EDIT_GROUP';
export const END_EDIT_GROUP = 'END_EDIT_GROUP';
