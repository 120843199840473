import {
  START_LOADING,
  END_LOADING,
  START_LOADING_CUSTOMERS,
  LOAD_CUSTOMERS,
  END_LOADING_CUSTOMERS
} from '../constants/customerConstants';

const initialState = {
  isLoading: false,
  customersList: [],
  sortOrder: 'desc',
  sortField: 'createdAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0,
  isResetEmailSent: false,
  isPassReset: false
};

const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };

    case END_LOADING:
      return { ...state, isLoading: false };
    case START_LOADING_CUSTOMERS:
      return { ...state, isLoading: true };

    case LOAD_CUSTOMERS:
      return {
        ...state,
        customersList: action.payload.data,
        count: action.payload.pagination.totalCount,
        page: action.payload.pagination.pageNumber,
        pageSize: action.payload.pagination.pageLimit,
        totalPages: action.payload.pagination.pageCount,
        sortOrder: action.payload.sort.order,
        sortField: action.payload.sort.field
      };

    case END_LOADING_CUSTOMERS:
      return { ...state, isLoading: false };
    default:
      return state;
  }
};

export default customersReducer;
