import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  ListItem,
  makeStyles,
  Collapse,
  List
} from '@material-ui/core';
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    borderRadius: 0,
    margin: 0,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    backgroundColor: fade(theme.palette.primary.main, 0.03),
    borderRadius: 0,
    borderLeft: `3px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  nestedList: {
    marginLeft: theme.spacing(3),
    paddingTop: 0,
    marginTop: 0
  }
}));

const NavItem = ({
  className,
  href,
  icon: Icon,
  title,
  nestedItems,
  size,
  isChild,
  ...rest
}) => {
  const classes = useStyles();
  const isExpandable = nestedItems && nestedItems.length > 0;
  const [open, setOpen] = React.useState(false);
  function handleClick() {
    setOpen(!open);
  }

  const menuItemRoot = (
    <ListItem
      dense
      className={classes.item}
      disableGutters
      onClick={handleClick}
      {...rest}
    >
      <Button
        size={size}
        activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        {/*<ListItemText className={classes.title} primary={title} inset={!Icon} />*/}
        <span className={classes.title}>{title}</span>
        {isExpandable && !open ? (
          <div>
            <IconExpandMore className={classes.icon} />
          </div>
        ) : null}
        {isExpandable && open ? (
          <div>
            <IconExpandLess className={classes.icon} />
          </div>
        ) : null}
      </Button>
    </ListItem>
  );
  const menuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List className={classes.nestedList}>
        {nestedItems.map((item, index) => (
          <NavItem
            ischild
            href={item.href}
            title={item.title}
            key={index}
            size="small"
          />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {menuItemRoot}
      {menuItemChildren}
    </>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  nestedItems: PropTypes.array
};

export default NavItem;
