const token = localStorage.getItem('token');
const authConfig = {
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    // Authorization: `Bearer ${token}`,
    'Content-type': 'application/json',
    Accept: 'application/json'
  }
};

export default authConfig;
