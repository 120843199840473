import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  LinearProgress
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
  getIsLoading,
  getPageNumber,
  getPageSize,
  getTotalPages,
  getTotalCustomers,
  getCustomersList
} from 'src/redux/accessors/customers';
import { getCustomers } from 'src/redux/actions/customerActions';
import { secureapi } from 'src/config/secureapi';
import EditMenu from './EditMenu';
import EmptyState from 'src/components/EmptyState';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.white,
    height: '100%',
    width: '100%'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  progress: {
    marginTop: theme.spacing(20),
    maxWidth: '20%',
    margin: 'auto'
  },
  head: {
    backgroundColor: theme.palette.background.dark
  },
  cell: { width: '250px' }
}));

const CustomersTable = ({
  className,
  customersList,
  getCustomers,
  isLoading,
  pageNumber,
  totalPages,
  pageSize,
  totalCustomers,
  ...rest
}) => {
  const classes = useStyles();
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  const handleSelectAll = event => {
    let newselectedUserIds;

    if (event.target.checked) {
      newselectedUserIds = customersList.map(user => user.id);
    } else {
      newselectedUserIds = [];
    }

    setSelectedUserIds(newselectedUserIds);
  };

  // const handleSelectOne = (event, id) => {
  //   const selectedIndex = selectedUserIds.indexOf(id);
  //   let newselectedUserIds = [];
  //
  //   if (selectedIndex === -1) {
  //     newselectedUserIds = newselectedUserIds.concat(selectedUserIds, id);
  //   } else if (selectedIndex === 0) {
  //     newselectedUserIds = newselectedUserIds.concat(selectedUserIds.slice(1));
  //   } else if (selectedIndex === selectedUserIds.length - 1) {
  //     newselectedUserIds = newselectedUserIds.concat(
  //       selectedUserIds.slice(0, -1)
  //     );
  //   } else if (selectedIndex > 0) {
  //     newselectedUserIds = newselectedUserIds.concat(
  //       selectedUserIds.slice(0, selectedIndex),
  //       selectedUserIds.slice(selectedIndex + 1)
  //     );
  //   }
  //
  //   setSelectedUserIds(newselectedUserIds);
  // };

  const handleLimitChange = event => {
    getCustomers(secureapi, 1, event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    getCustomers(secureapi, newPage + 1, pageSize);
  };

  useEffect(() => {
    getCustomers(secureapi, 1, 10);
  }, [getCustomers]);

  return (
    <div className={classes.root}>
      {isLoading ? (
        <LinearProgress className={classes.progress} />
      ) : customersList.length < 1 ? (
        <EmptyState message="No Customers found here" />
      ) : (
        <Card className={clsx(classes.root, className)} {...rest}>
          <PerfectScrollbar>
            <Box minWidth={700}>
              <Table size="small">
                {selectedUserIds.length === customersList.length ? (
                  <TableHead className={classes.head}>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={
                            selectedUserIds.length === customersList.length
                          }
                          color="primary"
                          indeterminate={
                            selectedUserIds.length > 0 &&
                            selectedUserIds.length < customersList.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      <TableCell>Actions Here</TableCell>
                    </TableRow>
                  </TableHead>
                ) : (
                  <TableHead className={classes.head}>
                    <TableRow>
                      <TableCell
                        size="small"
                        className={classes.cell}
                        // style={{ width: '150px' }}
                      >
                        Name
                      </TableCell>
                      <TableCell size="small" className={classes.cell}>
                        Phone Number
                      </TableCell>
                      <TableCell size="small" className={classes.cell}>
                        Estate
                      </TableCell>
                      <TableCell size="small" className={classes.cell}>
                        Block
                      </TableCell>
                      <TableCell size="small" align="right">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {customersList.slice(0, pageSize).map(customer => (
                    <TableRow
                      hover
                      key={customer.id}
                      selected={selectedUserIds.indexOf(customer.id) !== -1}
                    >
                      <TableCell size="small">
                        <Box alignItems="center" display="flex">
                          {/* <Avatar className={classes.avatar} src={user.avatarUrl}>
                        {getInitials(user.name)}
                      </Avatar> */}
                          <Typography color="textPrimary" variant="body1">
                            {customer.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell size="small">
                        {customer.phone_number}
                      </TableCell>
                      <TableCell size="small">{customer.estate}</TableCell>
                      <TableCell size="small">{customer.block}</TableCell>
                      <TableCell size="small" align="right">
                        <EditMenu customer={customer} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={Number(totalCustomers)}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            page={pageNumber - 1}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 25]}
          />
        </Card>
      )}
    </div>
  );
};

CustomersTable.propTypes = {
  className: PropTypes.string,
  customersList: PropTypes.array.isRequired
};

const mapState = state => ({
  customersList: getCustomersList(state),
  isLoading: getIsLoading(state),
  pageNumber: getPageNumber(state),
  totalPages: getTotalPages(state),
  pageSize: getPageSize(state),
  totalCustomers: getTotalCustomers(state)
});

const mapDispatch = {
  getCustomers
};

export default connect(mapState, mapDispatch)(CustomersTable);
