import {
  START_LOADING_PERMISSIONS,
  LOAD_PERMISSIONS,
  END_LOADING_PERMISSIONS,
  START_ADD_PERMISSIONS,
  ADD_PERMISSION_SUCCESS,
  END_ADD_PERMISSIONS,
  START_EDIT_PERMISSIONS,
  EDIT_PERMISSION_SUCCESS,
  END_EDIT_PERMISSIONS,
  START_DELETE_PERMISSIONS,
  DELETE_PERMISSION_SUCCESS,
  END_DELETE_PERMISSIONS
} from '../constants/permissionsContants';

const initialState = {
  isLoadingPermissions: false,
  isAddPermissionSuccess: false,
  isAddPermissionLoading: false,
  isEditPermissionLoading: false,
  isEditPermissionSuccess: false,
  isDeletePermissionLoading: false,
  isDeletePermissionSuccess: false,
  permissionsList: [],
  sortOrder: 'desc',
  sortField: 'createdAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const permissionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING_PERMISSIONS:
      return { ...state, isLoadingPermissions: true };
    case START_ADD_PERMISSIONS:
      return { ...state, isAddPermissionLoading: true };
    case END_ADD_PERMISSIONS:
      return { ...state, isAddPermissionLoading: false };
    case START_EDIT_PERMISSIONS:
      return { ...state, isEditPermissionLoading: true };
    case END_EDIT_PERMISSIONS:
      return { ...state, isEditPermissionLoading: false };
    case START_DELETE_PERMISSIONS:
      return { ...state, isDeletePermissionLoading: true };
    case END_DELETE_PERMISSIONS:
      return { ...state, isDeletePermissionLoading: false };
    case ADD_PERMISSION_SUCCESS:
      return { ...state, isAddPermissionSuccess: true };
    case EDIT_PERMISSION_SUCCESS:
      return { ...state, isEditPermissionSuccess: true };
    case DELETE_PERMISSION_SUCCESS:
      return { ...state, isDeletePermissionSuccess: true };

    case LOAD_PERMISSIONS:
      console.log('permissions payload>>>>', action.payload.sort.field);
      return {
        ...state,
        permissionsList: action.payload.data,
        count: action.payload.pagination.totalCount,
        page: action.payload.pagination.pageNumber,
        pageSize: action.payload.pagination.pageLimit,
        totalPages: action.payload.pagination.pageCount,
        sortOrder: action.payload.sort.order,
        sortField: action.payload.sort.field
      };

    case END_LOADING_PERMISSIONS:
      return { ...state, isLoadingPermissions: false };
    default:
      return state;
  }
};

export default permissionsReducer;
