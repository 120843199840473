export const SAVE_ACCESS_TOKEN = 'SAVE_ACCESS_TOKEN';
export const START_LOADING = 'START_LOADING';

export const END_LOADING = 'END_LOADING';
export const START_LOADING_USERS = 'START_LOADING_USERS';
export const LOAD_USERS = 'LOAD_USERS';
export const END_LOADING_USERS = 'END_LOADING_USERS';
export const RESET_PASSWORD_EMAIL_SENT = 'RESET_PASSWORD_EMAIL_SENT';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const START_ADD_USER = 'START_ADD_USER';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const END_ADD_USER = 'END_ADD_USER';

export const START_EDIT_USER = 'START_EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER = 'EDIT_USER';
export const END_EDIT_USER = 'END_EDIT_USER';
