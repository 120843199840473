import {
  START_LOADING,
  END_LOADING,
  ADD_USER_SUCCESS,
  START_ADD_USER,
  END_ADD_USER,
  START_EDIT_USER,
  EDIT_USER_SUCCESS,
  END_EDIT_USER,
  START_LOADING_USERS,
  LOAD_USERS,
  END_LOADING_USERS,
  RESET_PASSWORD_EMAIL_SENT,
  RESET_PASSWORD_SUCCESS
} from '../constants/userConstants';

const initialState = {
  isLoading: false,
  isAddUserSuccess: false,
  isAddUserLoading: false,
  isEditUserLoading: false,
  isEditUserSuccess: false,
  usersList: [],
  sortOrder: 'desc',
  sortField: 'createdAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0,
  isResetEmailSent: false,
  isPassReset: false
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_ADD_USER:
      return { ...state, isAddUserLoading: true };
    case END_ADD_USER:
      return { ...state, isAddUserLoading: false };
    case START_EDIT_USER:
      return { ...state, isEditUserLoading: true };
    case END_EDIT_USER:
      return { ...state, isEditUserLoading: false };
    case START_LOADING:
      return { ...state, isLoading: true };
    case ADD_USER_SUCCESS:
      return { ...state, isAddUserSuccess: true };
    case EDIT_USER_SUCCESS:
      return { ...state, isEditUserSuccess: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case START_LOADING_USERS:
      return { ...state, isLoading: true };

    case LOAD_USERS:
      return {
        ...state,
        usersList: action.payload.data,
        count: action.payload.pagination.totalCount,
        page: action.payload.pagination.pageNumber,
        pageSize: action.payload.pagination.pageLimit,
        totalPages: action.payload.pagination.pageCount,
        sortOrder: action.payload.sort.order,
        sortField: action.payload.sort.field
      };

    case END_LOADING_USERS:
      return { ...state, isLoading: false };

    case RESET_PASSWORD_EMAIL_SENT:
      return { ...state, isResetEmailSent: true };

    case RESET_PASSWORD_SUCCESS:
      return { ...state, isPassReset: true };
    default:
      return state;
  }
};

export default usersReducer;
