export const START_LOADING_PRODUCTS = 'START_LOADING_PRODUCTS';
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const END_LOADING_PRODUCTS = 'END_LOADING_PRODUCTS';

export const START_ADD_PRODUCT = 'START_ADD_PRODUCT';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const END_ADD_PRODUCT = 'END_ADD_PRODUCT';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';

export const START_EDIT_PRODUCT = 'START_EDIT_PRODUCT';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const END_EDIT_PRODUCT = 'END_EDIT_PRODUCT';
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS';

export const START_DELETE_PRODUCT = 'START_DELETE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const END_DELETE_PRODUCT = 'END_DELETE_PRODUCT';

export const START_PRODUCT_UPLOAD = 'START_PRODUCT_UPLOAD';
export const UPLOAD_PRODUCT = 'UPLOAD_PRODUCT';
export const END_PRODUCT_UPLOAD = 'END_PRODUCT_UPLOAD';

export const START_PRODUCT_IMAGES_UPLOAD = 'START_PRODUCT_IMAGES_UPLOAD';
export const UPLOAD_PRODUCT_IMAGES = 'UPLOAD_PRODUCT_IMAGES';
export const END_PRODUCT_IMAGES_UPLOAD = 'END_PRODUCT_IMAGES_UPLOAD';
