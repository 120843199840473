/*
Created on 4/13/21
Author: Patsheba Gikunda
*/
// check if user group can read a resource
export const canRead = Resource => {
  const userPermissions = JSON.parse(localStorage.getItem('groupPermissions'));
  const result = userPermissions.find((permission, index) => {
    if (permission.resource === Resource && permission.read === true)
      return true;
    else return false;
  });
  return result;
};

// check if user group can update a resource
export const canUpdate = Resource => {
  const userPermissions = JSON.parse(localStorage.getItem('groupPermissions'));
  const result = userPermissions.find((permission, index) => {
    if (permission.resource === Resource && permission.update === true)
      return true;
    else return false;
  });
  return result;
};

// check if user group can create a resource
export const canCreate = Resource => {
  const userPermissions = JSON.parse(localStorage.getItem('groupPermissions'));
  const result = userPermissions.find((permission, index) => {
    if (permission.resource === Resource && permission.create === true)
      return true;
    else return false;
  });
  return result;
};

// check if user group can delete a resource
export const canDelete = Resource => {
  const userPermissions = JSON.parse(localStorage.getItem('groupPermissions'));
  const result = userPermissions.find((permission, index) => {
    if (permission.resource === Resource && permission.delete === true)
      return true;
    else return false;
  });
  return result;
};
