import React, { useState, useEffect } from 'react';
import { Snackbar, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import MuiAlert from '@material-ui/lab/Alert';
import { getType, getMessage } from '../redux/accessors/notification';
import { clearMessage } from '../redux/actions/notification';

function Alert(props) {
  return <MuiAlert elevation={1} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(5)
    }
  }
}));

const Main = ({ type, message, clearMessage }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const anchorOrigin = { vertical: 'bottom', horizontal: 'right' };
  const duration = 10000;
  useEffect(() => {
    message && setOpen(true);
  }, [type, message]);
  const handleClose = () => {
    clearMessage();
    setOpen(false);
  };
  return (
    message && (
      <Snackbar
        className={classes.root}
        open={open}
        autoHideDuration={type === 'error' ? 60000 : duration}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
      >
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    )
  );
};
const mapState = state => ({
  type: getType(state),
  message: getMessage(state)
});
const mapDispatch = {
  clearMessage
};
export default connect(mapState, mapDispatch)(Main);
