import { combineReducers } from 'redux';
import notification from './notification';
import usersReducer from './usersReducer';
import customersReducer from './customersReducer';
import supermarketsReducer from './supermarketsReducer';
import stockReducer from './stockReducer';
import productsReducer from './productReducer';
import productCategoriesReducer from './productCategoriesReducer';
import ordersReducer from './orderReducer';
import userGroupsReducer from './userGroupReducer';
import permissionsReducer from './permissionsReducer';

export default combineReducers({
  notification,
  usersReducer,
  supermarketsReducer,
  stockReducer,
  productsReducer,
  productCategoriesReducer,
  ordersReducer,
  customersReducer,
  userGroupsReducer,
  permissionsReducer
});
