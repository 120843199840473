import {
  START_LOADING,
  END_LOADING,
  START_LOADING_STOCK,
  LOAD_STOCK,
  END_LOADING_STOCK,
  STOCK_OPERATION_SUCCESS,
  START_STOCK_UPLOAD,
  END_STOCK_UPLOAD,
  START_ADD_STOCK,
  END_ADD_STOCK,
  ADD_STOCK_SUCCESS,
  START_EDIT_STOCK,
  END_EDIT_STOCK,
  EDIT_STOCK_SUCCESS,
  START_DELETE_STOCK,
  END_DELETE_STOCK
} from '../constants/stockConstants';

const initialState = {
  isDeleteStockLoading: false,
  isAddStockLoading: false,
  isAddStockSuccess: false,
  isEditStockLoading: false,
  isLoading: false,
  isUploadStockLoading: false,
  stockList: [],
  isSuccess: false,
  sortOrder: 'desc',
  sortField: 'createdAt',
  page: 1,
  count: 0,
  pageSize: 10,
  totalPages: 0
};

const stockReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_ADD_STOCK:
      return {
        ...state,
        isAddStockLoading: true,
        isAddStockSuccess: false
      };
    case END_ADD_STOCK:
      return {
        ...state,
        isAddStockLoading: false
      };
    case ADD_STOCK_SUCCESS:
      return { ...state, isAddStockSuccess: true };
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case START_LOADING_STOCK:
      return { ...state, isLoading: true };
    case START_STOCK_UPLOAD:
      return { ...state, isUploadStockLoading: true };
    case END_STOCK_UPLOAD:
      return { ...state, isUploadStockLoading: false };
    case START_EDIT_STOCK:
      return { ...state, isEditStockLoading: true };
    case END_EDIT_STOCK:
      return { ...state, isEditStockLoading: false };
    case START_DELETE_STOCK:
      return { ...state, isDeleteStockLoading: true };
    case END_DELETE_STOCK:
      return { ...state, isDeleteStockLoading: false };

    case LOAD_STOCK:
      return {
        ...state,
        stockList: action.payload.data,
        count: action.payload.pagination.totalCount,
        page: action.payload.pagination.pageNumber,
        pageSize: action.payload.pagination.pageLimit,
        totalPages: action.payload.pagination.totalCount,
        sortOrder: action.payload.sort.order,
        sortField: action.payload.sort.field
      };

    case END_LOADING_STOCK:
      return { ...state, isLoading: false };
    case STOCK_OPERATION_SUCCESS:
      return { ...state, isSuccess: action.payload.isSuccess };
    default:
      return state;
  }
};

export default stockReducer;
